import cx from 'classnames'
import { useMemo } from 'react'

import { type SanityStrings } from '@data/sanity/queries/types/site'
import { defaultDecimalSeparator, getPriceWithCurrency } from '@lib/format'

type PriceBadgeSize = 'sm' | 'lg'

export type PriceBadgeStrings = Pick<
  SanityStrings,
  'productDiscountText' | 'taxExcludedText' | 'taxIncludedText'
>

export interface PriceBadgeProps {
  strings: PriceBadgeStrings
  price?: number
  comparePrice?: number
  currency?: string
  taxRate?: number
  taxesIncluded?: boolean
  size?: PriceBadgeSize
  showPrice?: boolean
  showNetPrice?: boolean
  showOldPrice?: boolean
  showOldNetPrice?: boolean
  showPriceTax?: boolean
  showNetPriceTax?: boolean
  showCurrency?: boolean
  showDiscountPercent?: boolean
  className?: string
  priceLineClassName?: string
  netPriceLineClassName?: string
}

const PriceBadge = ({
  strings,
  price,
  comparePrice,
  currency,
  taxRate,
  taxesIncluded,
  size,
  showPrice,
  showNetPrice,
  showOldPrice,
  showOldNetPrice,
  showPriceTax,
  showNetPriceTax,
  showCurrency,
  showDiscountPercent,
  className,
  priceLineClassName,
  netPriceLineClassName,
}: PriceBadgeProps) => {
  const formattedPrice = useMemo(() => {
    if (typeof price === 'undefined') {
      return
    }

    // Show placeholder price while shop information is loading
    if (typeof taxesIncluded === 'undefined') {
      return `-${defaultDecimalSeparator}--`
    }

    return getPriceWithCurrency(price, currency, {
      taxRate,
      applyTax: !taxesIncluded,
      showCurrency,
      hasTrailingZeros: true,
    })
  }, [currency, price, showCurrency, taxesIncluded, taxRate])

  const formattedOldPrice = useMemo(() => {
    if (typeof comparePrice === 'undefined') {
      return
    }

    if (typeof taxesIncluded === 'undefined') {
      return
    }

    return getPriceWithCurrency(comparePrice, currency, {
      taxRate,
      applyTax: !taxesIncluded,
      showCurrency,
      hasTrailingZeros: true,
    })
  }, [comparePrice, currency, showCurrency, taxesIncluded, taxRate])

  const formattedNetPrice = useMemo(() => {
    if (typeof price === 'undefined') {
      return
    }

    // Show placeholder price while shop information is loading
    if (typeof taxesIncluded === 'undefined') {
      return `-${defaultDecimalSeparator}--`
    }

    return getPriceWithCurrency(price, currency, {
      taxRate,
      removeTax: taxesIncluded,
      showCurrency,
      hasTrailingZeros: true,
    })
  }, [currency, price, showCurrency, taxesIncluded, taxRate])

  const formattedOldNetPrice = useMemo(() => {
    if (typeof comparePrice === 'undefined') {
      return
    }

    if (typeof taxesIncluded === 'undefined') {
      return
    }

    return getPriceWithCurrency(comparePrice, currency, {
      taxRate,
      removeTax: taxesIncluded,
      showCurrency,
      hasTrailingZeros: true,
    })
  }, [comparePrice, currency, showCurrency, taxesIncluded, taxRate])

  const formattedPriceTax = useMemo(() => {
    if (typeof taxRate === 'undefined') {
      return
    }

    const text = strings.taxIncludedText.replace(
      '{vatPercentage}',
      `${taxRate * 100}`
    )
    return `(${text})`
  }, [strings, taxRate])

  const formattedNetPriceTax = useMemo(() => {
    if (typeof taxRate === 'undefined') {
      return
    }

    const text = strings.taxExcludedText.replace(
      '{vatPercentage}',
      `${taxRate * 100}`
    )
    return `(${text})`
  }, [strings, taxRate])

  const discountPercent = useMemo(() => {
    if (typeof price === 'undefined' || typeof comparePrice === 'undefined') {
      return
    }

    const minPrice = Math.min(price, comparePrice)
    const maxPrice = Math.max(price, comparePrice)
    const discountPercent = ((maxPrice - minPrice) / maxPrice) * 100

    // If discount is 0-1%, return 1%
    if (discountPercent > 0 && discountPercent < 1) {
      return '1'
    }

    return Math.round(discountPercent).toString()
  }, [price, comparePrice])

  const formattedDiscountPercent = useMemo(() => {
    if (typeof discountPercent === 'undefined') {
      return
    }

    return strings.productDiscountText.replace(/{percent}/gi, discountPercent)
  }, [discountPercent, strings])

  return (
    <div className={cx('flex flex-col', className)}>
      <div
        className={cx(
          'flex items-center flex-nowrap gap-x-1 whitespace-nowrap',
          {
            'font-medium': !size || size === 'lg',
            'text-xs': size === 'sm',
            'text-sm': !size,
            'text-base': size === 'lg',
          },
          priceLineClassName
        )}
      >
        {!!showPrice && !!formattedPrice && (
          <span
            className={cx({
              'text-sm': size === 'sm',
              'text-base': !size,
              'text-lg': size === 'lg',
            })}
          >
            {formattedPrice}
          </span>
        )}

        {!!showOldPrice && !!formattedOldPrice && (
          <span className="line-through">{formattedOldPrice}</span>
        )}

        {!!showDiscountPercent && !!formattedDiscountPercent && (
          <span>{formattedDiscountPercent}</span>
        )}

        {!!showPriceTax && !!formattedPriceTax && (
          <span>{formattedPriceTax}</span>
        )}
      </div>

      <div
        className={cx(
          'flex items-center flex-nowrap gap-x-1 whitespace-nowrap opacity-70',
          {
            'text-xxs': size === 'sm',
            'text-xs': !size,
            'text-sm': size === 'lg',
          },
          netPriceLineClassName
        )}
      >
        {!!showNetPrice && !!formattedNetPrice && (
          <span>{formattedNetPrice}</span>
        )}

        {!!showOldNetPrice && !!formattedOldNetPrice && (
          <span className="line-through">{formattedOldNetPrice}</span>
        )}

        {!!showDiscountPercent && !!formattedDiscountPercent && (
          <span>{formattedDiscountPercent}</span>
        )}

        {!!showNetPriceTax && !!formattedNetPriceTax && (
          <span>{formattedNetPriceTax}</span>
        )}
      </div>
    </div>
  )
}

export default PriceBadge
