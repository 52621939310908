import {
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  type SanityCountBySlug,
  type SanityGeneralSettings,
  type SanitySiteFragment,
} from '@data/sanity/queries/types/site'

import { type SanityVideo } from '@data/sanity/queries/types/video'
import { screens } from '@lib/theme'

type MegaNavUpdateState = boolean | 'toggle'

interface SiteContextMegaNavigation {
  leftOffset?: number
  isOpen: boolean
  activeId?: string
}

interface SiteContextVideoModal {
  isOpen: boolean
  video?: SanityVideo
}

interface SiteContextMobileMenu {
  isOpen: boolean
}

type SiteContextMegaNavigationTimeout = number | null

interface SiteContextProps {
  headerHeight: number
  isRouteChanging: boolean
  megaNavigation: SiteContextMegaNavigation
  megaNavigationTimeout: SiteContextMegaNavigationTimeout
  mobileMenu: SiteContextMobileMenu
  videoModal: SiteContextVideoModal
  settings: SanityGeneralSettings | null
  setHeaderHeight: (height: number) => void
  setMegaNavigationTimeout: Dispatch<
    SetStateAction<SiteContextMegaNavigationTimeout>
  >
  toggleIsRouteChanging: (newState: boolean) => void
  toggleMegaNavigation: (updateState: MegaNavUpdateState, id?: string, leftOffset?: number) => void
  toggleMobileMenu: (isOpen: boolean) => void
  toggleVideoModal: (isOpen: boolean, video?: SanityVideo) => void
  allProductCount?: number
  getCollectionProductCount: (collectionSlug?: string) => number | undefined
  getCategoryProductCount: (productCategorySlug?: string) => number | undefined
}

const initialSiteContext: SiteContextProps = {
  headerHeight: 0,
  isRouteChanging: false,
  megaNavigation: {
    isOpen: false,
  },
  megaNavigationTimeout: null,
  mobileMenu: {
    isOpen: false,
  },
  videoModal: {
    isOpen: false,
  },
  settings: null,
  setHeaderHeight: () => null,
  setMegaNavigationTimeout: () => null,
  toggleIsRouteChanging: () => null,
  toggleMegaNavigation: () => null,
  toggleMobileMenu: () => null,
  toggleVideoModal: () => null,
  allProductCount: undefined,
  getCollectionProductCount: () => undefined,
  getCategoryProductCount: () => undefined,
}

interface SiteContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const SiteContext = createContext<SiteContextProps>(initialSiteContext)

export const SiteContextProvider = ({
  site,
  children,
}: SiteContextProviderProps) => {
  // State variables
  const [isRouteChanging, setIsRouteChanging] = useState<boolean>(
    initialSiteContext.isRouteChanging
  )
  const [mobileMenu, setMobileMenu] = useState<SiteContextMobileMenu>(
    initialSiteContext.mobileMenu
  )
  const [megaNavigation, setMegaNavigation] =
    useState<SiteContextMegaNavigation>(initialSiteContext.megaNavigation)

  const [megaNavigationTimeout, setMegaNavigationTimeout] =
    useState<SiteContextMegaNavigationTimeout>(null)

  const [allProductCount, setAllProductCount] = useState<number>(
    site.allProductCount
  )
  const [collectionProductCounts, setCollectionProductCounts] = useState<
    SanityCountBySlug[]
  >(site.collectionProductCounts)
  const [categoryProductCounts, setCategoryProductCounts] = useState<
    SanityCountBySlug[]
  >(site.categoryProductCounts)

  const [videoModal, setVideoModal] = useState<SiteContextVideoModal>(
    initialSiteContext.videoModal
  )

  const [settings, setSettings] = useState<SanityGeneralSettings>(
    site.generalSettings
  )
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    function handleResize() {
      // TODO: Optimize this to not set state that often.
      if (window.innerWidth >= parseInt(screens.lg)) {
        setMobileMenu({
          isOpen: false,
        })
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Update callbacks
  const toggleIsRouteChanging = useCallback(
    (newState: boolean) => setIsRouteChanging(newState),
    []
  )

  const toggleMegaNavigation = useCallback(
    (updateState: MegaNavUpdateState, id?: string, leftOffset?: number ) => {
      const getActiveId = (newState: boolean, id?: string) => {
        if (newState) {
          return id
        }
      }

      const isOpen =
        updateState === 'toggle' ? !megaNavigation.isOpen : updateState
      const activeId = getActiveId(isOpen, id)

      if (
        megaNavigation.isOpen !== isOpen ||
        megaNavigation.activeId !== activeId ||
        megaNavigation.leftOffset !== leftOffset
      ) {
        setMegaNavigation({ isOpen, activeId, leftOffset })
      }
    },
    [megaNavigation]
  )

  const toggleMobileMenu = useCallback(
    (isOpen: boolean) => {
      if (mobileMenu.isOpen !== isOpen) {
        setMobileMenu({
          isOpen,
        })
      }

      if (typeof window !== 'undefined') {
        document.body.classList.toggle('overflow-hidden', isOpen)
      }
    },
    [mobileMenu.isOpen]
  )

  const toggleVideoModal = useCallback(
    (isOpen: boolean, video?: SanityVideo) => {
      setVideoModal({
        isOpen,
        video,
      })
    },
    []
  )

  const getCollectionProductCount = useCallback(
    (collectionSlug?: string) =>
      collectionProductCounts?.find(({ slug }) => slug === collectionSlug)
        ?.count,
    [collectionProductCounts]
  )
  const getCategoryProductCount = useCallback(
    (productCategorySlug?: string) =>
      categoryProductCounts?.find(({ slug }) => slug === productCategorySlug)
        ?.count,
    [categoryProductCounts]
  )

  // Watch changes in site values (when switching language)
  useEffect(() => {
    setAllProductCount(site.allProductCount)
  }, [site.allProductCount])
  useEffect(() => {
    setCollectionProductCounts(site.collectionProductCounts)
  }, [site.collectionProductCounts])
  useEffect(() => {
    setCategoryProductCounts(site.categoryProductCounts)
  }, [site.categoryProductCounts])
  useEffect(() => setSettings(site.generalSettings), [site.generalSettings])

  return (
    <SiteContext.Provider
      value={{
        headerHeight,
        isRouteChanging,
        megaNavigation,
        megaNavigationTimeout,
        mobileMenu,
        videoModal,
        settings,
        setHeaderHeight,
        setMegaNavigationTimeout,
        toggleIsRouteChanging,
        toggleMegaNavigation,
        toggleMobileMenu,
        toggleVideoModal,
        allProductCount,
        getCollectionProductCount,
        getCategoryProductCount,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
