import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { compareNumbers } from './helpers'

export type Locale = 'nl' | 'en' | 'fr' | 'de'

export const defaultLocale: Locale = 'nl'

export const localeNames: Record<Locale, string> = {
  nl: 'Nederlands',
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
}

export const hasBlogLocaleContent: Record<Locale, boolean> = {
  nl: true,
  en: true,
  fr: true,
  de: true,
}

export const blogChildLocales: Record<Locale, Locale[]> = {
  nl: [],
  en: [],
  fr: [],
  de: [],
}

/**
 * Gets all locales.
 */
export const getAllLocales = (): Locale[] => ['nl', 'en', 'fr', 'de']

/**
 * Returns user's locales as IETF language tags, based on all available sources.
 * Source: https://github.com/wojtekmaj/get-user-locale
 */
const getBrowserLocales = () => {
  if (typeof window === 'undefined') {
    return [defaultLocale]
  }

  let locales: string[] = []

  if (window.navigator.languages) {
    locales = locales.concat(window.navigator.languages)
  }

  if (window.navigator.language) {
    locales.push(window.navigator.language)
  }

  if (window.navigator.userLanguage) {
    locales.push(window.navigator.userLanguage)
  }

  if (window.navigator.browserLanguage) {
    locales.push(window.navigator.browserLanguage)
  }

  if (window.navigator.systemLanguage) {
    locales.push(window.navigator.systemLanguage)
  }

  locales.push(defaultLocale)

  return locales
    .filter((locale, index, array) => array.indexOf(locale) === index)
    .map((locale) => getNormalizedLocale(locale))
}

/**
 * Gets normalized locale by fixing capitalization.
 */
export const getNormalizedLocale = (locale: string) => {
  if (
    !locale ||
    locale.indexOf('-') === -1 ||
    locale.toLowerCase() !== locale
  ) {
    return locale
  }

  const localeParts = locale.split('-')

  return `${localeParts[0]}-${localeParts[1].toUpperCase()}`
}

/**
 * Finds the best matching site locale from all site and browser locales.
 */
export const getMatchingSiteLocale = (
  siteLocales: string[],
  browserLocales: string[],
  defaultSiteLocale: string
) => {
  const matches = siteLocales
    .map((siteLocale) => {
      const exactIndex = browserLocales.indexOf(siteLocale)
      const partialIndex = browserLocales
        .map((locale) => locale.split('-')[0])
        .indexOf(siteLocale.split('-')[0])

      return {
        locale: siteLocale,
        index: exactIndex > -1 ? exactIndex : partialIndex,
      }
    })
    .filter(({ index }) => index > -1)
    .sort((match1, match2) => compareNumbers(match1.index, match2.index))

  if (matches.length > 0) {
    return matches[0].locale
  }

  return defaultSiteLocale
}

/**
 * Visitor's preferred locale detection hook.
 * Returns preferred locale (if it doesn't match site's locale) and a method to confirm current locale.
 */
export const usePreferredLocale = (locale: Locale) => {
  const [preferredLocale, setPreferredLocale] = useState<string | null>(null)
  const [browserLocales, setBrowserLocales] = useState<string[]>([])
  const [isLocaleConfirmed, setIsLocaleConfirmed] = useLocalStorage<boolean>(
    'is_locale_confirmed',
    false
  )

  // Detect browser locales
  useEffect(() => {
    if (isLocaleConfirmed) {
      return
    }

    const newBrowserLocales = getBrowserLocales()

    setBrowserLocales(newBrowserLocales)
  }, [isLocaleConfirmed])

  // Find best matching site locale based on browser locales
  useEffect(() => {
    if (isLocaleConfirmed || browserLocales.length === 0) {
      setPreferredLocale(null)

      return
    }

    const allLocales = getAllLocales()
    const matchingSiteLocale = getMatchingSiteLocale(
      allLocales,
      browserLocales,
      defaultLocale
    )

    // Save preferred locale if it's not already being used
    setPreferredLocale(
      matchingSiteLocale !== locale ? matchingSiteLocale : null
    )
  }, [browserLocales, isLocaleConfirmed, locale])

  const confirmLocale = useCallback(() => {
    if (!isLocaleConfirmed) {
      setIsLocaleConfirmed(true)
    }
  }, [isLocaleConfirmed, setIsLocaleConfirmed])

  return [preferredLocale as Locale | null, confirmLocale] as const
}
