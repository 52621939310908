import { PortableTextListItemComponent } from '@portabletext/react';
import Icon from '@components/icon';

const CheckmarkListItem: PortableTextListItemComponent = ({ children }) => (
  <li className="flex items-start space-x-3 mt-2">
    <span className="flex justify-center items-center rounded-full bg-green text-white w-5 h-5 flex-shrink-0">
      <Icon name="Checkmark" id="" className="text-white w-4 h-4" />
    </span>
    <div className="text-left leading-text flex-1">{children}</div>
  </li>
);

export default CheckmarkListItem;
