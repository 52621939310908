import {
  addCartDeliveryAddressesMutation,
  addCartLinesMutation,
  createCartMutation,
  removeCartDeliveryAddressesMutation,
  removeCartLinesMutation,
  updateCartAttributesMutation,
  updateCartBuyerIdentityMutation,
  updateCartDeliveryAddressesMutation,
  updateCartDiscountCodesMutation,
  updateCartLinesMutation,
  updateCartNoteMutation,
} from '@data/shopify/storefront/mutations/cart'
import { getCartQuery } from '@data/shopify/storefront/queries/cart'
import {
  type AddCartDeliveryAddressesMutation,
  type AddCartDeliveryAddressesMutationVariables,
  type AddCartLinesMutation,
  type AddCartLinesMutationVariables,
  type AttributeInput,
  type CartBuyerIdentityInput,
  type CartFragmentFragment,
  type CartInput,
  type CartLineInput,
  type CartLineUpdateInput,
  type CartSelectableAddressInput,
  type CartSelectableAddressUpdateInput,
  type CreateCartMutation,
  type CreateCartMutationVariables,
  type GetCartQuery,
  type GetCartQueryVariables,
  type RemoveCartDeliveryAddressesMutation,
  type RemoveCartDeliveryAddressesMutationVariables,
  type RemoveCartLinesMutation,
  type RemoveCartLinesMutationVariables,
  type UpdateCartAttributesMutation,
  type UpdateCartAttributesMutationVariables,
  type UpdateCartBuyerIdentityMutation,
  type UpdateCartBuyerIdentityMutationVariables,
  type UpdateCartDeliveryAddressesMutation,
  type UpdateCartDeliveryAddressesMutationVariables,
  type UpdateCartDiscountCodesMutation,
  type UpdateCartDiscountCodesMutationVariables,
  type UpdateCartLinesMutation,
  type UpdateCartLinesMutationVariables,
  type UpdateCartNoteMutation,
  type UpdateCartNoteMutationVariables,
} from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import {
  type ShopifyClient,
  mutateShopifyStorefront,
  queryShopifyStorefront,
} from './client'
import { getUserErrorMessage, getWarningMessage } from './helpers'

/**
 * Gets a Shopify cart.
 */
export const getShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string
) => {
  const getCartResult = await queryShopifyStorefront<
    GetCartQuery,
    GetCartQueryVariables
  >(locale, shopifyStorefrontGraphQlClient, getCartQuery, {
    cartId,
  })

  const cart = getCartResult.data?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Creates a new Shopify cart.
 */
export const createShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartInput: CartInput
) => {
  const createCartResult = await mutateShopifyStorefront<
    CreateCartMutation,
    CreateCartMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, createCartMutation, {
    cartInput,
  })

  const userErrorMessage = getUserErrorMessage(
    createCartResult.data?.cartCreate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = createCartResult.data?.cartCreate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates buyer indentity in Shopify cart.
 */
export const updateShopifyCartBuyerIdentity = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  buyerIdentity: CartBuyerIdentityInput
) => {
  const updateCartBuyerIdentityResult = await mutateShopifyStorefront<
    UpdateCartBuyerIdentityMutation,
    UpdateCartBuyerIdentityMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartBuyerIdentityMutation, {
    cartId,
    buyerIdentity,
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartBuyerIdentityResult.data?.cartBuyerIdentityUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart =
    updateCartBuyerIdentityResult.data?.cartBuyerIdentityUpdate?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates discount codes in Shopify cart.
 */
export const updateShopifyCartDiscountCodes = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  discountCodes: string[]
) => {
  const updateCartDiscountCodesResult = await mutateShopifyStorefront<
    UpdateCartDiscountCodesMutation,
    UpdateCartDiscountCodesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartDiscountCodesMutation, {
    cartId,
    discountCodes,
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartDiscountCodesResult.data?.cartDiscountCodesUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart =
    updateCartDiscountCodesResult.data?.cartDiscountCodesUpdate?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates attributes for Shopify cart.
 */
export const updateShopifyCartAttrbites = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  attributes: AttributeInput[]
) => {
  const updateCartAttributesResult = await mutateShopifyStorefront<
    UpdateCartAttributesMutation,
    UpdateCartAttributesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartAttributesMutation, {
    cartId,
    attributes,
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartAttributesResult.data?.cartAttributesUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart =
    updateCartAttributesResult.data?.cartAttributesUpdate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates note for Shopify cart.
 */
export const updateShopifyCartNote = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  note: string
) => {
  const updateCartNoteResult = await mutateShopifyStorefront<
    UpdateCartNoteMutation,
    UpdateCartNoteMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartNoteMutation, {
    cartId,
    note,
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartNoteResult.data?.cartNoteUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = updateCartNoteResult.data?.cartNoteUpdate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Adds new line items to Shopify cart.
 */
export const addLineItemsToShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lines: CartLineInput[]
) => {
  const addCartLinesResult = await mutateShopifyStorefront<
    AddCartLinesMutation,
    AddCartLinesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, addCartLinesMutation, {
    cartId,
    lines,
  })

  const userErrorMessage = getUserErrorMessage(
    addCartLinesResult.data?.cartLinesAdd?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = addCartLinesResult.data?.cartLinesAdd?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates line items in Shopify cart.
 */
export const updateLineItemsInShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lines: CartLineUpdateInput[]
) => {
  const updateCartLinesResult = await mutateShopifyStorefront<
    UpdateCartLinesMutation,
    UpdateCartLinesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCartLinesMutation, {
    cartId,
    lines,
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartLinesResult.data?.cartLinesUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = updateCartLinesResult.data?.cartLinesUpdate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Removes line items from Shopify cart.
 */
export const removeLineItemsFromShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lineIds: string[]
) => {
  const removeCartLinesResult = await mutateShopifyStorefront<
    RemoveCartLinesMutation,
    RemoveCartLinesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, removeCartLinesMutation, {
    cartId,
    lineIds,
  })

  const userErrorMessage = getUserErrorMessage(
    removeCartLinesResult.data?.cartLinesRemove?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = removeCartLinesResult.data?.cartLinesRemove?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Adds delivery addresses to Shopify cart.
 */
export const addShopifyCartDeliveryAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  addresses: CartSelectableAddressInput[]
) => {
  const addCartDeliveryAddressesResult = await mutateShopifyStorefront<
    AddCartDeliveryAddressesMutation,
    AddCartDeliveryAddressesMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, addCartDeliveryAddressesMutation, {
    cartId,
    addresses,
  })

  const userErrorMessage = getUserErrorMessage(
    addCartDeliveryAddressesResult.data?.cartDeliveryAddressesAdd?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const warningMessage = getWarningMessage(
    addCartDeliveryAddressesResult.data?.cartDeliveryAddressesAdd?.warnings
  )

  if (warningMessage) {
    throw new Error(warningMessage)
  }

  const cart =
    addCartDeliveryAddressesResult.data?.cartDeliveryAddressesAdd?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates delivery addresses in Shopify cart.
 */
export const updateShopifyCartDeliveryAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  addresses: CartSelectableAddressUpdateInput[]
) => {
  const updateCartDeliveryAddressesResult = await mutateShopifyStorefront<
    UpdateCartDeliveryAddressesMutation,
    UpdateCartDeliveryAddressesMutationVariables
  >(
    locale,
    shopifyStorefrontGraphQlClient,
    updateCartDeliveryAddressesMutation,
    {
      cartId,
      addresses,
    }
  )

  const userErrorMessage = getUserErrorMessage(
    updateCartDeliveryAddressesResult.data?.cartDeliveryAddressesUpdate
      ?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const warningMessage = getWarningMessage(
    updateCartDeliveryAddressesResult.data?.cartDeliveryAddressesUpdate
      ?.warnings
  )

  if (warningMessage) {
    throw new Error(warningMessage)
  }

  const cart =
    updateCartDeliveryAddressesResult.data?.cartDeliveryAddressesUpdate?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Removes delivery addresses from Shopify cart.
 */
export const removeShopifyCartDeliveryAddresses = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  addressIds: string[]
) => {
  const removeCartDeliveryAddressesResult = await mutateShopifyStorefront<
    RemoveCartDeliveryAddressesMutation,
    RemoveCartDeliveryAddressesMutationVariables
  >(
    locale,
    shopifyStorefrontGraphQlClient,
    removeCartDeliveryAddressesMutation,
    {
      cartId,
      addressIds,
    }
  )

  const userErrorMessage = getUserErrorMessage(
    removeCartDeliveryAddressesResult.data?.cartDeliveryAddressesRemove
      ?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const warningMessage = getWarningMessage(
    removeCartDeliveryAddressesResult.data?.cartDeliveryAddressesRemove
      ?.warnings
  )

  if (warningMessage) {
    throw new Error(warningMessage)
  }

  const cart =
    removeCartDeliveryAddressesResult.data?.cartDeliveryAddressesRemove?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}
