import { type Locale } from './language'

/**
 * Gets Shopify shop ID local storage key.
 */
export const getShopifyShopIdStorageKey = (locale: Locale) =>
  `${locale}_shopify_shop_id`

/**
 * Gets Shopify cart ID local storage key.
 */
export const getShopifyCartIdStorageKey = (locale: Locale) =>
  `${locale}_cart_id_v2`
