import { type SanityClient } from '@sanity/client'

import {
  type AttributeInput,
  type CartFragmentFragment,
} from '@data/shopify/storefront/types'
import {
  type AutomaticDiscount,
  type Cart,
  type CartAddress,
  type LineItem,
} from '@lib/cart/types'
import { type Locale } from '@lib/language'
import { getSanityClient } from '@lib/sanity/client'
import { getProductVariants } from '@lib/sanity/product-variant'
import { parseShopifyGlobalId } from './helpers'

type CartFragmentFragmentLine =
  CartFragmentFragment['lines']['edges'][0]['node']

/**
 * Parses Shopify line items.
 */
const parseShopifyCartLineItems = async (
  locale: Locale,
  sanityClient: SanityClient,
  lineItems: CartFragmentFragmentLine[]
) => {
  // Get product variant data from Sanity
  const variantIds = lineItems
    .map((lineItem) =>
      lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null
    )
    .filter(Boolean) as number[]
  const productVariants = await getProductVariants(
    sanityClient,
    locale,
    variantIds
  )

  return lineItems
    .map((lineItem) => {
      const variantId = lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null

      const variant = variantId
        ? productVariants.find(
            (productVariant) => productVariant.variantID === variantId
          )
        : null

      if (!variant) {
        return
      }

      const variantLineItem: LineItem = {
        lineId: lineItem.id,
        quantity: lineItem.quantity,
        attributes: lineItem.attributes
          .filter((attribute) => !!attribute.value)
          .map((attribute) => ({
            key: attribute.key,
            value: attribute.value,
          })) as AttributeInput[],
        ...variant,
      }
      return variantLineItem
    })
    .filter(Boolean) as LineItem[]
}

/**
 * Parses Shopify cart.
 */
export const parseShopifyCart = async (
  locale: Locale,
  shopifyCart?: CartFragmentFragment
) => {
  if (!shopifyCart) {
    return
  }

  const sanityClient = getSanityClient()

  // Lien items
  const lineItems = shopifyCart.lines.edges.map((edge) => edge.node)
  const parsedLineItems = await parseShopifyCartLineItems(
    locale,
    sanityClient,
    lineItems
  )

  // Discounts
  const automaticDiscount = lineItems.reduce<AutomaticDiscount>(
    (previousAutomaticDiscount, lineItem) =>
      lineItem.discountAllocations.reduce<AutomaticDiscount>(
        (subPreviousAutomaticDiscount, discountAllocation) => {
          if (
            discountAllocation.__typename !== 'CartAutomaticDiscountAllocation'
          ) {
            return subPreviousAutomaticDiscount
          }

          return {
            title: discountAllocation.title,
            amount:
              (subPreviousAutomaticDiscount.amount ?? 0) +
              Number(discountAllocation.discountedAmount.amount) * 100,
          }
        },
        previousAutomaticDiscount
      ),
    {}
  )
  const discountCodes = shopifyCart.discountCodes.map(
    (discountCode) => discountCode.code
  )

  // Shipping
  const shippingAddresses = shopifyCart.delivery?.addresses?.map(
    (shippingAddress) => {
      const cartAddress: CartAddress = {
        id: shippingAddress.id,
        selected: shippingAddress.selected,
        address1: shippingAddress.address.address1 ?? undefined,
        address2: shippingAddress.address.address2 ?? undefined,
        city: shippingAddress.address.city ?? undefined,
        countryCode: shippingAddress.address.countryCode ?? undefined,
        zip: shippingAddress.address.zip ?? undefined,
      }

      return cartAddress
    }
  )
  const shippingOptions = shopifyCart.deliveryGroups?.edges ?? []
  const shippingOption = shippingOptions[0]?.node?.selectedDeliveryOption

  // Prices
  const subtotal = shopifyCart.cost.subtotalAmount
    ? Number(shopifyCart.cost.subtotalAmount.amount) * 100
    : 0
  const shipping = shippingOption?.estimatedCost?.amount
    ? Number(shippingOption.estimatedCost.amount) * 100
    : undefined

  const cart: Cart = {
    id: shopifyCart.id,
    lineItems: parsedLineItems,
    prices: {
      subtotal,
      shipping,
      automaticDiscount: automaticDiscount.amount,
    },
    webUrl: `${shopifyCart.checkoutUrl}`,
    customerId: shopifyCart.buyerIdentity.customer?.id,
    shippingAddresses,
    hasShippingOptions: !!shippingOption,
    shippingTitle: shippingOption?.title ?? undefined,
    automaticDiscount,
    discountCodes,
  }
  return cart
}
