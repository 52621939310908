import { PortableText } from '@portabletext/react'
import {
  type ArbitraryTypedObject,
  type PortableTextBlock,
} from '@portabletext/types'

import ColoredText from './portable-text/colored-text'
import Link from './portable-text/link'
import CheckmarkListItem from '@components/portable-text/checkmarkListItem'

type SimplePortableTextBlock = PortableTextBlock | ArbitraryTypedObject

interface SimplePortableTextProps {
  content: SimplePortableTextBlock[]
  className?: string
}

const SimplePortableText = ({
  content,
  className,
}: SimplePortableTextProps) => {
  return (
    <div className={className}>
      <PortableText
        value={content}
        components={{
          block: {
            none: ({ children }) => <>{children}</>,
            normal: ({ children }) => <p>{children}</p>,
          },
          marks: {
            link: Link,
            textColor: ColoredText,
          },
          list: {
            bullet: ({ children }) => <ul className="list-disc ml-5">{children}</ul>,
            checkmark: ({ children }) => <ul className="list-checkmark !pl-0">{children}</ul>,
          },
          listItem: {
            bullet: ({ children }) => <li>{children}</li>,
            checkmark: CheckmarkListItem
          },
        }}
      />
    </div>
  )
}

export default SimplePortableText
