import { useMemo } from 'react'

import { type SanityProductVariantOption } from '@data/sanity/queries/types/product'
import { defaultOptionName, defaultOptionValue } from '@lib/product/product'

interface CartLineItemOptionsProps {
  options: SanityProductVariantOption[]
}

const CartLineItemOptions = ({ options }: CartLineItemOptionsProps) => {
  const isDefaultOptionOnly = useMemo(() => {
    if (!options || options.length !== 1) {
      return false
    }

    const option = options[0]

    return (
      option.name === defaultOptionName && option.value === defaultOptionValue
    )
  }, [options])

  if (isDefaultOptionOnly) {
    return null
  }

  return (
    <p className="inline-flex flex-wrap gap-x-1">
      {options.map((option, index) => (
        <span key={index} className="whitespace-nowrap">
          <span className="font-medium">{option.name}:</span> {option.value}
          {index < options.length - 1 ? ',' : ''}
        </span>
      ))}
    </p>
  )
}

export default CartLineItemOptions
