import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSummaryDiscountProps {
  amount: number
  title?: string
  quantity?: number
  className?: string
}

const CartSummaryDiscount = ({
  amount,
  title,
  quantity,
  className,
}: CartSummaryDiscountProps) => {
  const { currency, taxesIncluded, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const formattedPrice = useMemo(() => {
    if (typeof taxesIncluded === 'undefined') {
      return
    }

    const totalAmount = amount * (quantity ?? 1)
    const amountWithCurrency = getPriceWithCurrency(totalAmount, currency, {
      taxRate,
      applyTax: !taxesIncluded,
      showCurrency: true,
      hasTrailingZeros: true,
    })

    return `-${amountWithCurrency}`
  }, [amount, currency, quantity, taxesIncluded, taxRate])

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <div className="inline-flex flex-wrap gap-x-5 items-center">
        {!!strings.cartDiscount && <span>{strings.cartDiscount}</span>}
        <span className="text-gray-700">
          {title}
          {!!quantity && `(x${quantity})`}
        </span>
      </div>

      <span className="shrink-0">{formattedPrice}</span>
    </div>
  )
}

export default CartSummaryDiscount
