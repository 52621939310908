import {
  type DraftOrderLineItemInput,
  type DraftOrderInput,
  type ShippingLineInput,
  type ShippingRateFragmentFragment,
} from '@data/shopify/admin/types'
import { type OrderFragmentFragment } from '@data/shopify/storefront/types'
import { type Cart } from '@lib/cart/types'
import {
  type UserAddress,
  type UserCustomer,
  type UserOrder,
} from '@lib/user/types'
import { getShopifyMailingAddressInput } from './address'
import { getShopifyGlobalId } from './helpers'

export interface CreateOrderPayload {
  cart: Cart
  customer: UserCustomer
  addresses: UserAddress[]
}

export interface CreateOrderResponse {
  error?: string
}

/**
 * Parses Shopify customer order.
 */
export const parseShopifyOrder = (order: OrderFragmentFragment) => {
  const userOrder: UserOrder = {
    id: order.name,
    date: order.processedAt,
    paymentStatus: order.financialStatus ?? null,
    fulfillmentStatus: order.fulfillmentStatus,
    total: Number(order.totalPrice.amount),
    url: order.statusUrl,
  }

  return userOrder
}

/**
 * Gets Shopify order shipping line input from available shipping rates.
 */
export const getOrderShippingLine = (
  shippingRates: ShippingRateFragmentFragment[]
) => {
  if (shippingRates.length === 0) {
    return
  }

  const shippingRate = shippingRates[0]
  const shippingLine: ShippingLineInput = {
    shippingRateHandle: shippingRate.handle,
    title: shippingRate.title,
    priceWithCurrency: {
      amount: shippingRate.price.amount,
      currencyCode: shippingRate.price.currencyCode,
    },
  }

  return shippingLine
}

/**
 * Gets Shopify draft order input from cart, customer and addresses.
 */
export const getShopifyDraftOrderInput = (
  cart: Cart,
  customer: UserCustomer,
  addresses: UserAddress[],
  paymentTermsTemplateId?: string,
  shippingLine?: ShippingLineInput
) => {
  const customerId = getShopifyGlobalId('Customer', customer.customerId)
  const lineItems: DraftOrderLineItemInput[] = cart.lineItems.map(
    (lineItem) => ({
      variantId: getShopifyGlobalId('ProductVariant', lineItem.variantID),
      quantity: lineItem.quantity,
    })
  )
  const address = getShopifyMailingAddressInput(addresses)

  const input: DraftOrderInput = {
    purchasingEntity: {
      customerId,
    },
    lineItems,
    discountCodes: cart.discountCodes,
    billingAddress: address,
    shippingAddress: address,
    tags: ['B2B'],
  }

  if (paymentTermsTemplateId) {
    input.paymentTerms = {
      paymentTermsTemplateId,
    }
  }

  if (shippingLine) {
    input.shippingLine = shippingLine
  }

  return input
}
