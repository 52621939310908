import { type AttributeInput } from '@data/shopify/storefront/types'
import { type CartFormValues } from '@lib/cart/types'

// Namespaces
export const shopifyCustomNamespace = 'custom'
export const shopifyGlobalNamespace = 'global'
export const shopifySanityNamespace = 'sanity'

// Metafield keys
export const shopifyCustomerSyncMetafield = 'customer_sync'
export const shopifyDescriptionTagMetafield = 'description_tag'
export const shopifyProductIdMetafield = 'product_id'
export const shopifyProductSyncMetafield = 'product_sync'
export const shopifyTitleTagMetafield = 'title_tag'
export const shopifyVatNumberMetafield = 'vat_number'

// Attribute names
export const shopifyTaxExemptAttribute = '_tax_exempt'
export const shopifyTaxVatIdAttribute = '_vat_id'

// Attribute values
export const shopifyYesAttributeValue = 'yes'
export const shopifyNoAttributeValue = 'no'

/**
 * Gets tax-related cart attributes.
 */
export const getTaxCartAttributes = (
  cartFormValues: CartFormValues,
  taxExempt: boolean
) => {
  const attributes: AttributeInput[] = []

  if (cartFormValues.vatId) {
    // Set VAT ID checkout attribute
    attributes.push({
      key: shopifyTaxVatIdAttribute,
      value: cartFormValues.vatId,
    })

    // Set tax exemption checkout attribute
    attributes.push({
      key: shopifyTaxExemptAttribute,
      value: taxExempt ? shopifyYesAttributeValue : shopifyNoAttributeValue,
    })
  }

  return attributes
}
