import cx from 'classnames'
import { useCallback, useContext } from 'react'

import { CartContext } from '@lib/cart/context'
import { useRemoveItemFromCart, useUpdateCartItem } from '@lib/cart/hooks'
import { type LineItem } from '@lib/cart/types'

import CartEmpty from './empty'
import CartLineItem from './line-item'

interface CartItemsProps {
  lineItems: LineItem[]
  className?: string
  itemClassName?: string
}

const CartLineItems = ({
  lineItems,
  className,
  itemClassName,
}: CartItemsProps) => {
  const { toggleCart } = useContext(CartContext)

  const updateCartItem = useUpdateCartItem()
  const removeItemFromCart = useRemoveItemFromCart()

  /**
   * Handles updating product quantity in cart.
   */
  const handleUpdateItem = useCallback(
    (lineId: string, quantity: number) => updateCartItem([lineId], quantity),
    [updateCartItem]
  )

  /**
   * Handles removing product from cart.
   */
  const handleRemoveItem = useCallback(
    (lineId: string) => removeItemFromCart([lineId]),
    [removeItemFromCart]
  )

  if (lineItems.length === 0) {
    return <CartEmpty />
  }

  return (
    <div className={cx('flex flex-col gap-y-5', className)}>
      {lineItems.map((lineItem) => (
        <CartLineItem
          key={lineItem.lineId}
          lineId={lineItem.lineId}
          price={lineItem.price}
          options={lineItem.options}
          quantity={lineItem.quantity}
          product={lineItem.product}
          productUrl={lineItem.productUrl}
          imageUrl={lineItem.imageUrl}
          onUpdateItem={handleUpdateItem}
          onRemoveItem={handleRemoveItem}
          onClose={() => toggleCart(false)}
          className={itemClassName}
        />
      ))}
    </div>
  )
}

export default CartLineItems
