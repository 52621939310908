import cx from 'classnames'
import { type ButtonHTMLAttributes, forwardRef, ReactNode, useId } from 'react'

import { ScreenSizeName, screenSizeNames } from '@lib/theme'

import {
  type SanityButtonColor,
  type SanityButtonIconAlignment,
  type SanityButtonSize,
  type SanityButtonVariant,
} from '@data/sanity/queries/types/link'

import Icon, { IconName } from '@components/icon'

// TODO: Get rid of these friggin' enums.

export enum ButtonVariant {
  LINK = 'link',
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum ButtonSize {
  DEFAULT = 'default',
  XS = 'xs',
  SMALL = 'small',
  NORMAL = 'normal',
}

export enum ButtonColor {
  INHERIT = 'inherit',
  DEFAULT = 'default',
  WHITE = 'white',
  BLACK = 'black',
  GRAY = 'gray',
  BLUE = 'blue',
}

export enum ButtonIconAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

type VariantColorClassMap = Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
>

export type SizeClassMap = Record<ButtonSize, string | string[]>

export type ResponsiveSizeClassMap = Record<ScreenSizeName, SizeClassMap>

export type ColorClassMap = Record<ButtonColor, string | string[]>

interface ButtonLabelProps {
  children: ReactNode
  hideUntil?: ScreenSizeName
}

interface ButtonIconProps {
  name: IconName
  alignment?: ButtonIconAlignment | null
  size?: ButtonSize
  className?: string
}

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
  hideLabelUntil?: ScreenSizeName
}

export const getBaseClasses = (
  variant?: ButtonVariant,
  color?: ButtonColor,
  hasIcon?: boolean
) =>
  cx('inline-flex items-center disabled:opacity-50 transition-all', {
    'justify-center rounded-full whitespace-nowrap text-center border-[1px] leading-[1.25]':
      variant !== ButtonVariant.LINK,
    'underline hover:no-underline': variant === ButtonVariant.LINK && !hasIcon,
    'font-normal hover:opacity-60': variant === ButtonVariant.LINK && hasIcon,
    'hover:opacity-80': variant === ButtonVariant.FILLED,
  })

export const colorClasses: VariantColorClassMap = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.WHITE]: ['border-white bg-white text-black'],
    [ButtonColor.BLACK]: ['border-black bg-black text-white'],
    [ButtonColor.GRAY]: ['border-gray-300 bg-gray-300 text-black'],
    [ButtonColor.BLUE]: [
      'border-white bg-white text-blue',
    ],
    [ButtonColor.DEFAULT]: [
      'bg-btn-filled-bg text-btn-filled-text border-btn-filled-border',
      'hover:bg-btn-filled-bg-hover hover:text-btn-filled-text-hover hover:border-btn-filled-border-hover',
    ],
    [ButtonColor.INHERIT]: [],
  },
  [ButtonVariant.OUTLINED]: {
    [ButtonColor.WHITE]: [
      'bg-transparent border-white text-white',
      'hover:bg-white hover:text-black',
    ],
    [ButtonColor.BLACK]: [
      'bg-transparent border-black text-black',
      'hover:bg-black hover:text-white',
    ],
    [ButtonColor.GRAY]: [
      'bg-transparent border-gray-300 text-black',
      'hover:border-gray-200',
    ],
    [ButtonColor.BLUE]: [
      'bg-transparent border-blue text-blue',
      'hover:bg-blue hover:text-white',
    ],
    [ButtonColor.DEFAULT]: [
      'bg-btn-outlined-bg border border-btn-outlined-border text-btn-outlined-text',
      'hover:bg-btn-outlined-bg-hover hover:text-btn-outlined-text-hover hover:border-btn-outlined-border-hover',
    ],
    [ButtonColor.INHERIT]: ['bg-transparent border-current text-current'],
  },
  [ButtonVariant.LINK]: {
    [ButtonColor.WHITE]: ['text-white'],
    [ButtonColor.BLACK]: ['text-black'],
    [ButtonColor.GRAY]: ['text-gray-300'],
    [ButtonColor.BLUE]: ['text-blue'],
    [ButtonColor.DEFAULT]: ['text-current'],
    [ButtonColor.INHERIT]: ['text-current'],
  },
}

const sizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: 'font-semibold',
  [ButtonSize.XS]: 'text-sm font-medium',
  [ButtonSize.SMALL]: 'text-sm font-medium',
  [ButtonSize.NORMAL]: 'text-sm font-semibold',
}

const spacingClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.XS]: 'px-2 py-1.5 gap-x-2',
  [ButtonSize.SMALL]: 'px-5.5 py-2.5 gap-x-2',
  [ButtonSize.NORMAL]: 'px-8 py-4.5 gap-x-2',
}

// TODO: Make `generateResponsiveClasses` work to generate Tailwind classes ahead using them to avoid the madness below.
const responsiveSpacingClasses: ResponsiveSizeClassMap = {
  xs: {
    [ButtonSize.DEFAULT]: '',
    [ButtonSize.XS]: 'xs:px-2 py-1.5 xs:gap-x-2',
    [ButtonSize.SMALL]: 'xs:px-5.5 xs:py-2.5 xs:gap-x-2',
    [ButtonSize.NORMAL]: 'xs:px-8 xs:py-4.5 xs:gap-x-2',
  },
  sm: {
    [ButtonSize.DEFAULT]: '',
    [ButtonSize.XS]: 'xs:px-2 py-1.5 xs:gap-x-2',
    [ButtonSize.SMALL]: 'sm:px-5.5 sm:py-2.5 sm:gap-x-2',
    [ButtonSize.NORMAL]: 'sm:px-8 sm:py-4.5 sm:gap-x-2',
  },
  md: {
    [ButtonSize.DEFAULT]: '',
    [ButtonSize.XS]: 'sm:px-2 py-1.5 sm:gap-x-2',
    [ButtonSize.SMALL]: 'md:px-5.5 md:py-2.5 md:gap-x-2',
    [ButtonSize.NORMAL]: 'md:px-8 md:py-4.5 md:gap-x-2',
  },
  lg: {
    [ButtonSize.DEFAULT]: '',
    [ButtonSize.XS]: 'lg:px-2 py-1.5 lg:gap-x-2',
    [ButtonSize.SMALL]: 'lg:px-5.5 lg:py-2.5 lg:gap-x-2',
    [ButtonSize.NORMAL]: 'lg:px-8 lg:py-4.5 lg:gap-x-2',
  },
  xl: {
    [ButtonSize.DEFAULT]: '',
    [ButtonSize.XS]: 'xl:px-2 py-1.5 xl:gap-x-2',
    [ButtonSize.SMALL]: 'xl:px-5.5 xl:py-2.5 xl:gap-x-2',
    [ButtonSize.NORMAL]: 'xl:px-8 xl:py-4.5 xl:gap-x-2',
  },
}

const iconSizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.XS]: 'text-lg',
  [ButtonSize.SMALL]: 'text-xl',
  [ButtonSize.NORMAL]: 'text-xl',
}

const iconSpacingClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.XS]: 'p-1',
  [ButtonSize.SMALL]: 'p-2',
  [ButtonSize.NORMAL]: 'p-3',
}

const activeClasses: VariantColorClassMap = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.WHITE]: 'border-white bg-white text-black',
    [ButtonColor.BLACK]: 'border-black bg-black text-white',
    [ButtonColor.GRAY]: 'border-gray-300 bg-gray-300 text-black',
    [ButtonColor.BLUE]: 'border-white bg-white text-blue',
    [ButtonColor.DEFAULT]:
      'bg-btn-filled-bg-hover !text-btn-filled-text-hover border-btn-filled-border-hover',
    [ButtonColor.INHERIT]: '',
  },
  [ButtonVariant.OUTLINED]: {
    [ButtonColor.WHITE]: 'border-white bg-white !text-black',
    [ButtonColor.BLACK]: 'border-black bg-black !text-white',
    [ButtonColor.GRAY]: '!border-black !bg-black !text-white',
    [ButtonColor.BLUE]: 'border-blue bg-blue !text-white',
    [ButtonColor.DEFAULT]:
      'bg-btn-outlined-bg-hover !text-btn-outlined-text-hover border-btn-outlined-border-hover',
    [ButtonColor.INHERIT]:
      'bg-transparent border-current text-current opacity-60',
  },
  [ButtonVariant.LINK]: colorClasses[ButtonVariant.LINK],
}

const labelVisibilityClasses: Record<ScreenSizeName, string> = {
  xs: 'hidden xs:inline',
  sm: 'hidden sm:inline',
  md: 'hidden md:inline',
  lg: 'hidden lg:inline',
  xl: 'hidden xl:inline',
}

export const getButtonVariant = (variant?: SanityButtonVariant) => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityButtonSize) => {
  if (!size) {
    return ButtonSize.DEFAULT
  }

  return size as ButtonSize
}

export const getButtonColor = (color?: SanityButtonColor) => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityButtonIconAlignment
) => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

interface GetButtonStylesProps extends ButtonProps {
  children?: ReactNode
}

export const getButtonStyles = ({
  variant = ButtonVariant.LINK,
  color = ButtonColor.DEFAULT,
  size = ButtonSize.NORMAL,
  isActive,
  icon,
  hideLabelUntil,
  children,
}: GetButtonStylesProps) => {
  const hasChildren = Boolean(children)
  const isLabelHidden =
    hideLabelUntil && screenSizeNames.includes(hideLabelUntil)
  const iconOnly = (icon && !hasChildren) || isLabelHidden

  const defaultSpacing = iconOnly
    ? iconSpacingClasses[size]
    : spacingClasses[size]

  const responsiveSpacing = hideLabelUntil
    ? `${iconSpacingClasses[size]} ${responsiveSpacingClasses[hideLabelUntil][size]}`
    : defaultSpacing

  // Using optional chaining operators below because Sanity could contain a deprecated variant, color or size
  return cx(
    getBaseClasses(variant, color, Boolean(icon)),
    colorClasses[variant]?.[color],
    isActive ? activeClasses[variant]?.[color] : '',
    variant !== ButtonVariant.LINK && responsiveSpacing,
    sizeClasses[size]
  )
}

export const ButtonLabel = ({ children, hideUntil }: ButtonLabelProps) => (
  <span className={hideUntil ? labelVisibilityClasses[hideUntil] : ''}>
    {children}
  </span>
)

export const ButtonIcon = ({
  name,
  size,
  alignment = ButtonIconAlignment.RIGHT,
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        {
          'order-first': alignment === ButtonIconAlignment.LEFT,
        },
        size ? iconSizeClasses[size] : '',
        className
      )}
    >
      <Icon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onBeforeInput,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconAlignment,
      isActive,
      hideLabelUntil,
    }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    ref
  ) => (
    <button
      type={type}
      id={id}
      ref={ref}
      className={cx(
        'group',
        getButtonStyles({
          variant,
          size,
          color,
          isActive,
          icon,
          hideLabelUntil,
          children,
        }),
        className
      )}
      onClick={onClick}
      disabled={disabled}
      style={style}
      onBeforeInput={onBeforeInput}
      aria-label={ariaLabel}
    >
      {children && (
        <ButtonLabel hideUntil={hideLabelUntil}>{children}</ButtonLabel>
      )}
      {icon && <ButtonIcon name={icon} alignment={iconAlignment} size={size} />}
    </button>
  )
)

Button.displayName = 'Button'

export default Button
