import { CountryCode } from '@data/shopify/admin/types'
import { type Locale } from './language'

export const defaultCountryCodes: Record<Locale, CountryCode> = {
  nl: CountryCode.Nl,
  en: CountryCode.Gb,
  fr: CountryCode.Fr,
  de: CountryCode.De,
}

type CountryNames = Record<CountryCode, string>

export const englishCountryNames: CountryNames = {
  AC: 'Ascension Island',
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AR: 'Argentina',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'St. Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Caribbean Netherlands',
  BR: 'Brazil',
  BS: 'The Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Democratic Republic of the Congo',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: `Côte d'Ivoire`,
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FO: 'Faeroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'The Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'St. Martin',
  MG: 'Madagascar',
  MK: 'Former Yugoslav Republic of Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macau',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn Islands',
  PS: 'Palestinian Territories',
  PT: 'Portugal',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'São Tomé and Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'East Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  ZZ: 'Unknown Region',
}

export const countryNames: Record<Locale, CountryNames> = {
  nl: {
    AC: 'Ascension Island',
    AD: 'Andorra',
    AE: 'Verenigde Arabische Emiraten',
    AF: 'Afghanistan',
    AG: 'Antigua en Barbuda',
    AI: 'Anguilla',
    AL: 'Albanië',
    AM: 'Armenië',
    AN: 'Nederlandse Antillen',
    AO: 'Angola',
    AR: 'Argentinië',
    AT: 'Oostenrijk',
    AU: 'Australië',
    AW: 'Aruba',
    AX: 'Åland Islands',
    AZ: 'Azerbeidzjan',
    BA: 'Bosnië en Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'België',
    BF: 'Burkina Faso',
    BG: 'Bulgarije',
    BH: 'Bahrein',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Caribisch gebied Nederland',
    BR: 'Brazilië',
    BS: `De Bahama's`,
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Wit-Rusland',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Democratische Republiek Congo',
    CF: 'Centraal-Afrikaanse Republiek',
    CG: 'Congo',
    CH: 'Zwitserland',
    CI: 'Ivoorkust',
    CK: 'Cookeilanden',
    CL: 'Chili',
    CM: 'Kameroen',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Kaap Verde',
    CW: 'Curaçao',
    CX: 'Christmaseiland',
    CY: 'Cyprus',
    CZ: 'Tsjechië',
    DE: 'Duitsland',
    DJ: 'Djibouti',
    DK: 'Denemarken',
    DM: 'Dominica',
    DO: 'Dominicaanse Republiek',
    DZ: 'Algerije',
    EC: 'Ecuador',
    EE: 'Estland',
    EG: 'Egypte',
    EH: 'Westelijke Sahara',
    ER: 'Eritrea',
    ES: 'Spanje',
    ET: 'Ethiopië',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falklandeilanden',
    FO: 'Faeröereilanden',
    FR: 'Frankrijk',
    GA: 'Gabon',
    GB: 'Verenigd Koninkrijk',
    GD: 'Grenada',
    GE: 'Georgië',
    GF: 'Frans-Guyana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatoriaal-Guinea',
    GR: 'Griekenland',
    GS: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    GT: 'Guatemala',
    GW: 'Guinee-Bissau',
    GY: 'Guyana',
    HK: 'Hongkong',
    HM: 'Heard-eiland en McDonaldeilanden',
    HN: 'Honduras',
    HR: 'Kroatië',
    HT: 'Haïti',
    HU: 'Hongarije',
    ID: 'Indonesië',
    IE: 'Ierland',
    IL: 'Israël',
    IM: 'Man',
    IN: 'India',
    IO: 'Britse Indische Oceaan Territorium',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'IJsland',
    IT: 'Italië',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordanië',
    JP: 'Japan',
    KE: 'Kenia',
    KG: 'Kirgizië',
    KH: 'Cambodja',
    KI: 'Kiribati',
    KM: 'Comoren',
    KN: 'Saint Kitts en Nevis',
    KP: 'Noord-Korea',
    KR: 'Zuid-Korea',
    KW: 'Koeweit',
    KY: 'Kaaimaneilanden',
    KZ: 'Kazachstan',
    LA: 'Laos',
    LB: 'Libanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litouwen',
    LU: 'Luxemburg',
    LV: 'Letland',
    LY: 'Libië',
    MA: 'Marokko',
    MC: 'Monaco',
    MD: 'Moldavië',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagaskar',
    MK: 'Voormalige Joegoslavische Republiek Macedonië',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolië',
    MO: 'Macau',
    MQ: 'Martinique',
    MR: 'Mauritanië',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Malediven',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Maleisië',
    MZ: 'Mozambique',
    NA: 'Namibië',
    NC: 'Nieuw-Caledonië',
    NE: 'Niger',
    NF: 'Norfolk Eiland',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Nederland',
    NO: 'Noorwegen',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nieuw-Zeeland',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Frans-Polynesië',
    PG: 'Papoea-Nieuw-Guinea',
    PH: 'Filipijnen',
    PK: 'Pakistan',
    PL: 'Polen',
    PM: 'Saint Pierre en Miquelon',
    PN: 'Pitcairneilanden',
    PS: 'Palestijnse Gebieden',
    PT: 'Portugal',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Roemenië',
    RS: 'Servië',
    RU: 'Rusland',
    RW: 'Rwanda',
    SA: 'Saoedi-Arabië',
    SB: 'Salomonseilanden',
    SC: 'Seychellen',
    SD: 'Soedan',
    SE: 'Zweden',
    SG: 'Singapore',
    SH: 'Sint-Helena',
    SI: 'Slovenië',
    SJ: 'Spitsbergen en Jan Mayen',
    SK: 'Slowakije',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalië',
    SR: 'Suriname',
    SS: 'Zuid-Soedan',
    ST: 'São Tomé en Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syrië',
    SZ: 'Swaziland',
    TA: 'Tristan da Cunha',
    TC: 'Turks- en Caicoseilanden',
    TD: 'Tsjaad',
    TF: 'Frans Zuidelijk Gebieden',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tadzjikistan',
    TK: 'Tokelau',
    TL: 'Oost-Timor',
    TM: 'Turkmenistan',
    TN: 'Tunesië',
    TO: 'Tonga',
    TR: 'Turkije',
    TT: 'Trinidad en Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Oekraïne',
    UG: 'Oeganda',
    UM: 'Kleine afgelegen eilanden van de Verenigde Staten',
    US: 'Verenigde Staten',
    UY: 'Uruguay',
    UZ: 'Oezbekistan',
    VA: 'Vaticaanstad',
    VC: 'Saint Vincent en de Grenadines',
    VE: 'Venezuela',
    VG: 'Britse Maagdeneilanden',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis en Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Jemen',
    YT: 'Mayotte',
    ZA: 'Zuid-Afrika',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    ZZ: 'Onbekende regio',
  },
  en: englishCountryNames,
  fr: {
    AC: "Île de l'Ascension",
    AD: 'Andorre',
    AE: 'Émirats arabes unis',
    AF: 'Afghanistan',
    AG: 'Antigua-et-Barbuda',
    AI: 'Anguilla',
    AL: 'Albanie',
    AM: 'Arménie',
    AN: 'Antilles néerlandaises',
    AO: 'Angola',
    AR: 'Argentine',
    AT: 'Autriche',
    AU: 'Australie',
    AW: 'Aruba',
    AX: 'Îles Åland',
    AZ: 'Azerbaïdjan',
    BA: 'Bosnie-Herzégovine',
    BB: 'Barbade',
    BD: 'Bangladesh',
    BE: 'Belgique',
    BF: 'Burkina Faso',
    BG: 'Bulgarie',
    BH: 'Bahreïn',
    BI: 'Burundi',
    BJ: 'Bénin',
    BL: 'Saint-Barthélemy',
    BM: 'Bermudes',
    BN: 'Brunéi',
    BO: 'Bolivie',
    BQ: 'Pays-Bas caribéens',
    BR: 'Brésil',
    BS: 'Les Bahamas',
    BT: 'Bhoutan',
    BV: 'Île Bouvet',
    BW: 'Botswana',
    BY: 'Biélorussie',
    BZ: 'Bélize',
    CA: 'Canada',
    CC: 'Îles Cocos (Keeling)',
    CD: 'République démocratique du Congo',
    CF: 'République centrafricaine',
    CG: 'Congo',
    CH: 'Suisse',
    CI: "Côte d'Ivoire",
    CK: 'Îles Cook',
    CL: 'Chili',
    CM: 'Cameroun',
    CN: 'Chine',
    CO: 'Colombie',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cap-Vert',
    CW: 'Curaçao',
    CX: 'Noël Île',
    CY: 'Chypre',
    CZ: 'République tchèque',
    DE: 'Allemagne',
    DJ: 'Djibouti',
    DK: 'Danemark',
    DM: 'Dominique',
    DO: 'République dominicaine',
    DZ: 'Algérie',
    EC: 'Équateur',
    EE: 'Estonie',
    EG: 'Égypte',
    EH: 'Sahara occidental',
    ER: 'Érythrée',
    ES: 'Espagne',
    ET: 'Éthiopie',
    FI: 'Finlande',
    FJ: 'Fidji',
    FK: 'Îles Malouines',
    FO: 'Îles Féroé',
    FR: 'France',
    GA: 'Gabon',
    GB: 'Royaume-Uni',
    GD: 'Grenade',
    GE: 'Géorgie',
    GF: 'Guyane française',
    GG: 'Guernesey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenland',
    GM: 'Gambie',
    GN: 'Guinée',
    GP: 'Guadeloupe',
    GQ: 'Guinée équatoriale',
    GR: 'Grèce',
    GS: 'Géorgie du Sud et Sandwich du Sud Îles',
    GT: 'Guatemala',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Île Heard et îles McDonald',
    HN: 'Honduras',
    HR: 'Croatie',
    HT: 'Haïti',
    HU: 'Hongrie',
    ID: 'Indonésie',
    IE: 'Irlande',
    IL: 'Israël',
    IM: 'Île de Man',
    IN: 'Inde',
    IO: 'Océan Indien britannique Territoire',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Islande',
    IT: 'Italie',
    JE: 'Jersey',
    JM: 'Jamaïque',
    JO: 'Jordanie',
    JP: 'Japon',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KH: 'Cambodge',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'Saint-Kitts-et-Nevis',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    KW: 'Koweït',
    KY: 'Îles Caïmans',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Liban',
    LC: 'Sainte-Lucie',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    LV: 'Lettonie',
    LY: 'Libye',
    MA: 'Maroc',
    MC: 'Monaco',
    MD: 'Moldavie',
    ME: 'Monténégro',
    MF: 'Saint-Martin',
    MG: 'Madagascar',
    MK: 'Ancienne République yougoslave de Macédoine',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolie',
    MO: 'Macao',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MS: 'Montserrat',
    MT: 'Malte',
    MU: 'Maurice',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexique',
    MY: 'Malaisie',
    MZ: 'Mozambique',
    NA: 'Namibie',
    NC: 'Nouvelle-Calédonie',
    NE: 'Niger',
    NF: 'Norfolk Île',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Pays-Bas',
    NO: 'Norvège',
    NP: 'Népal',
    NR: 'Nauru',
    NU: 'Nioué',
    NZ: 'Nouvelle-Zélande',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Pérou',
    PF: 'Polynésie française',
    PG: 'Papouasie-Nouvelle-Guinée',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Pologne',
    PM: 'Saint-Pierre-et-Miquelon',
    PN: 'Îles Pitcairn',
    PS: 'Territoires palestiniens',
    PT: 'Portugal',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Roumanie',
    RS: 'Serbie',
    RU: 'Russie',
    RW: 'Rwanda',
    SA: 'Arabie Saoudite',
    SB: 'Îles Salomon',
    SC: 'Seychelles',
    SD: 'Soudan',
    SE: 'Suède',
    SG: 'Singapour',
    SH: 'Sainte-Hélène',
    SI: 'Slovénie',
    SJ: 'Svalbard et Jan Mayen',
    SK: 'Slovaquie',
    SL: 'Sierra Leone',
    SM: 'Saint-Marin',
    SN: 'Sénégal',
    SO: 'Somalie',
    SR: 'Surinam',
    SS: 'Soudan du Sud',
    ST: 'São Tomé et Príncipe',
    SV: 'Salvador',
    SX: 'Saint-Martin',
    SY: 'Syrie',
    SZ: 'Swaziland',
    TA: 'Tristan de Cunha',
    TC: 'Îles Turques et Caïques',
    TD: 'Tchad',
    TF: 'Sud de la France Territoires',
    TG: 'Aller',
    TH: 'Thaïlande',
    TJ: 'Tadjikistan',
    TK: 'Tokélaou',
    TL: 'Timor oriental',
    TM: 'Turkménistan',
    TN: 'Tunisie',
    TO: 'Tonga',
    TR: 'Turquie',
    TT: 'Trinité-et-Tobago',
    TV: 'Tuvalu',
    TW: 'Taïwan',
    TZ: 'Tanzanie',
    UA: 'Ukraine',
    UG: 'Ouganda',
    UM: 'Îles mineures éloignées des États-Unis',
    US: 'États-Unis',
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VA: 'Cité du Vatican',
    VC: 'Saint-Vincent-et-les Grenadines',
    VE: 'Venezuela',
    VG: 'Îles Vierges britanniques',
    VN: 'Viêt Nam',
    VU: 'Vanuatu',
    WF: 'Wallis et Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yémen',
    YT: 'Mayotte',
    ZA: 'Afrique du Sud',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
    ZZ: 'Région inconnue',
  },
  de: {
    AC: 'Ascension-Insel',
    AD: 'Andorra',
    AE: 'Vereinigte Arabische Emirate',
    AF: 'Afghanistan',
    AG: 'Antigua und Barbuda',
    AI: 'Anguilla',
    AL: 'Albanien',
    AM: 'Armenien',
    AN: 'Niederländische Antillen',
    AO: 'Angola',
    AR: 'Argentinien',
    AT: 'Österreich',
    AU: 'Australien',
    AW: 'Aruba',
    AX: 'Åland-Inseln',
    AZ: 'Aserbaidschan',
    BA: 'Bosnien und Herzegowina',
    BB: 'Barbados',
    BD: 'Bangladesch',
    BE: 'Belgien',
    BF: 'Burkina Faso',
    BG: 'Bulgarien',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivien',
    BQ: 'Karibische Niederlande',
    BR: 'Brasilien',
    BS: 'Die Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvetinsel',
    BW: 'Botswana',
    BY: 'Weißrussland',
    BZ: 'Belize',
    CA: 'Kanada',
    CC: 'Kokosinseln (Keelinginseln).',
    CD: 'Demokratische Republik Kongo',
    CF: 'Zentralafrikanische Republik',
    CG: 'Kongo',
    CH: 'Schweiz',
    CI: 'Elfenbeinküste',
    CK: 'Cookinseln',
    CL: 'Chile',
    CM: 'Kamerun',
    CN: 'China',
    CO: 'Kolumbien',
    CR: 'Costa Rica',
    CU: 'Kuba',
    CV: 'Kap Verde',
    CW: 'Curaçao',
    CX: 'Weihnachtsinsel',
    CY: 'Zypern',
    CZ: 'Tschechische Republik',
    DE: 'Deutschland',
    DJ: 'Dschibuti',
    DK: 'Dänemark',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    DZ: 'Algerien',
    EC: 'Ecuador',
    EE: 'Estland',
    EG: 'Ägypten',
    EH: 'Westsahara',
    ER: 'Eritrea',
    ES: 'Spanien',
    ET: 'Äthiopien',
    FI: 'Finnland',
    FJ: 'Fidschi',
    FK: 'Falklandinseln',
    FO: 'Färöer',
    FR: 'Frankreich',
    GA: 'Gabun',
    GB: 'Vereinigtes Königreich',
    GD: 'Grenada',
    GE: 'Georgien',
    GF: 'Französisch-Guayana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Grönland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Äquatorialguinea',
    GR: 'Griechenland',
    GS: 'Südgeorgien und die Südlichen Sandwichinseln',
    GT: 'Guatemala',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hongkong',
    HM: 'Heard und McDonaldinseln',
    HN: 'Honduras',
    HR: 'Kroatien',
    HT: 'Haiti',
    HU: 'Ungarn',
    ID: 'Indonesien',
    IE: 'Irland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'Indien',
    IO: 'Britischer Indischer Ozean Territorium',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Island',
    IT: 'Italien',
    JE: 'Jersey',
    JM: 'Jamaika',
    JO: 'Jordanien',
    JP: 'Japan',
    KE: 'Kenia',
    KG: 'Kirgisistan',
    KH: 'Kambodscha',
    KI: 'Kiribati',
    KM: 'Komoren',
    KN: 'St. Kitts und Nevis',
    KP: 'Nordkorea',
    KR: 'Südkorea',
    KW: 'Kuwait',
    KY: 'Kaimaninseln',
    KZ: 'Kasachstan',
    LA: 'Laos',
    LB: 'Libanon',
    LC: 'St. Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litauen',
    LU: 'Luxemburg',
    LV: 'Lettland',
    LY: 'Libyen',
    MA: 'Marokko',
    MC: 'Monaco',
    MD: 'Moldawien',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagaskar',
    MK: 'Ehemalige jugoslawische Republik von Mazedonien',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolei',
    MO: 'Macau',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Malediven',
    MW: 'Malawi',
    MX: 'Mexiko',
    MY: 'Malaysia',
    MZ: 'Mosambik',
    NA: 'Namibia',
    NC: 'Neukaledonien',
    NE: 'Niger',
    NF: 'Norfolk Insel',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Niederlande',
    NO: 'Norwegen',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Neuseeland',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Französisch-Polynesien',
    PG: 'Papua-Neuguinea',
    PH: 'Philippinen',
    PK: 'Pakistan',
    PL: 'Polen',
    PM: 'Saint-Pierre und Miquelon',
    PN: 'Pitcairninseln',
    PS: 'Palästinensische Gebiete',
    PT: 'Portugal',
    PY: 'Paraguay',
    QA: 'Katar',
    RE: 'Wiedervereinigung',
    RO: 'Rumänien',
    RS: 'Serbien',
    RU: 'Russland',
    RW: 'Ruanda',
    SA: 'Saudi-Arabien',
    SB: 'Salomonen',
    SC: 'Seychellen',
    SD: 'Sudan',
    SE: 'Schweden',
    SG: 'Singapur',
    SH: 'St. Helena',
    SI: 'Slowenien',
    SJ: 'Spitzbergen und Jan Mayen',
    SK: 'Slowakei',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    SS: 'Südsudan',
    ST: 'São Tomé und Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syrien',
    SZ: 'Swasiland',
    TA: 'Tristan da Cunha',
    TC: 'Turks- und Caicosinseln',
    TD: 'Tschad',
    TF: 'Französischer Süden Gebiete',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tadschikistan',
    TK: 'Tokelau',
    TL: 'Osttimor',
    TM: 'Turkmenistan',
    TN: 'Tunesien',
    TO: 'Tonga',
    TR: 'Truthahn',
    TT: 'Trinidad und Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tansania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'Kleinere vorgelagerte Inseln der Vereinigten Staaten',
    US: 'Vereinigte Staaten',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VA: 'Vatikanstadt',
    VC: 'St. Vincent und die Grenadinen',
    VE: 'Venezuela',
    VG: 'Britische Jungferninseln',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis und Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Jemen',
    YT: 'Mayotte',
    ZA: 'Südafrika',
    ZM: 'Sambia',
    ZW: 'Simbabwe',
    ZZ: 'Unbekannte Region',
  },
}
