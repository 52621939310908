import {
  type ApolloClientOptions,
  type DocumentNode,
  type NormalizedCacheObject,
  type OperationVariables,
  InMemoryCache,
  ApolloClient,
} from '@apollo/client'
import {
  createStorefrontClient,
  getShopifyCookies,
  SHOPIFY_S,
  SHOPIFY_STOREFRONT_ID_HEADER,
  SHOPIFY_STOREFRONT_S_HEADER,
  SHOPIFY_STOREFRONT_Y_HEADER,
  SHOPIFY_Y,
} from '@shopify/hydrogen-react'

import { getLocaleVariable } from '@lib/environment'
import { type Locale } from '@lib/language'
import { getShopifyShopIdStorageKey } from '@lib/local-storage'
import {
  getLocaleFromShopifyStoreDomain,
  getShopifyStoreDomainFromLocales,
} from '../helpers'

export type ShopifyClient = ApolloClient<NormalizedCacheObject>

export const shopifyApiVersion = '2025-01'

/**
 * Returns Apollo client for Shopify Storefront GraphQL API.
 */
export const getShopifyStorefrontGraphQlClient = (locale: Locale) => {
  const shopifyStoreDomain = getShopifyStoreDomainFromLocales(locale)
  const shopifyStorefrontAccessToken = getLocaleVariable(
    locale,
    'NEXT_PUBLIC_SHOPIFY_API_TOKEN'
  )

  if (!shopifyStorefrontAccessToken) {
    throw new Error('Shopify storefront token missing')
  }

  const storefrontClient = createStorefrontClient({
    storeDomain: shopifyStoreDomain,
    publicStorefrontToken: shopifyStorefrontAccessToken,
    storefrontApiVersion: shopifyApiVersion,
    contentType: 'json',
  })

  const inMemoryCache = new InMemoryCache()
  const options: ApolloClientOptions<NormalizedCacheObject> = {
    uri: storefrontClient.getStorefrontApiUrl(),
    headers: storefrontClient.getPublicTokenHeaders(),
    cache: inMemoryCache,
  }

  return new ApolloClient(options)
}

/**
 * Returns Apollo client for Shopify Admin GraphQL API.
 */
export const getShopifyAdminGraphQlClient = (
  shopifyStoreDomain?: string,
  locale?: Locale
) => {
  if (!shopifyStoreDomain) {
    throw new Error('Shopify store domain missing')
  }

  const normalizedLocale =
    locale ?? getLocaleFromShopifyStoreDomain(shopifyStoreDomain)
  const shopifyAdminAccessToken = getLocaleVariable(
    normalizedLocale,
    'SHOPIFY_API_PASSWORD'
  )

  if (!shopifyAdminAccessToken) {
    throw new Error('Shopify admin token missing')
  }

  const inMemoryCache = new InMemoryCache()
  const options: ApolloClientOptions<NormalizedCacheObject> = {
    uri: `https://${shopifyStoreDomain}/admin/api/${shopifyApiVersion}/graphql.json`,
    headers: {
      'X-Shopify-Access-Token': shopifyAdminAccessToken,
    },
    cache: inMemoryCache,
  }

  return new ApolloClient(options)
}

/**
 * Gets headers for Shopify queries and mutations.
 */
const getShopifyHeaders = (locale: Locale) => {
  const shopifyCookies = getShopifyCookies(
    typeof document !== 'undefined' ? document.cookie : ''
  )
  const customHeaders: Record<string, string> = {
    [SHOPIFY_STOREFRONT_Y_HEADER]: shopifyCookies[SHOPIFY_Y],
    [SHOPIFY_STOREFRONT_S_HEADER]: shopifyCookies[SHOPIFY_S],
  }

  if (typeof window !== 'undefined') {
    // Get shop ID from local storage
    const shopifyShopIdStorageKey = getShopifyShopIdStorageKey(locale)
    const shopifyShopId = localStorage.getItem(shopifyShopIdStorageKey)

    if (shopifyShopId) {
      customHeaders[SHOPIFY_STOREFRONT_ID_HEADER] = shopifyShopId
    }
  }

  return customHeaders
}

/**
 * Queries a Shopify Storefront GraphQL endpoint.
 */
export const queryShopifyStorefront = async <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables
>(
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  query: DocumentNode,
  variables?: TVariables
) => {
  const shopifyHeaders = getShopifyHeaders(locale)

  const result = await shopifyStorefrontGraphQlClient.query<TData, TVariables>({
    query,
    variables,
    context: {
      headers: {
        ...shopifyHeaders,
      },
    },
  })

  return result
}
/**
 * Mutates a Shopify Storefront GraphQL endpoint.
 */
export const mutateShopifyStorefront = async <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables
>(
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  mutation: DocumentNode,
  variables?: TVariables
) => {
  const shopifyHeaders = getShopifyHeaders(locale)

  const result = await shopifyStorefrontGraphQlClient.mutate<TData, TVariables>(
    {
      mutation,
      variables,
      context: {
        headers: {
          ...shopifyHeaders,
        },
      },
    }
  )

  return result
}

/**
 * Queries a Shopify Admin GraphQL endpoint.
 */
export const queryShopifyAdmin = async <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables
>(
  shopifyAdminGraphQlClient: ShopifyClient,
  query: DocumentNode,
  variables?: TVariables
) => {
  const result = await shopifyAdminGraphQlClient.query<TData, TVariables>({
    query,
    variables,
  })

  return result
}
/**
 * Mutates a Shopify Admin GraphQL endpoint.
 */
export const mutateShopifyAdmin = async <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables
>(
  shopifyAdminGraphQlClient: ShopifyClient,
  mutation: DocumentNode,
  variables?: TVariables
) => {
  const result = await shopifyAdminGraphQlClient.mutate<TData, TVariables>({
    mutation,
    variables,
  })

  return result
}
