import { type Locale } from './language'

type EnvironmentVariableKey =
  | 'NEXT_PUBLIC_SHOPIFY_STORE_ID'
  | 'NEXT_PUBLIC_SHOPIFY_API_TOKEN'
  | 'SHOPIFY_API_PASSWORD'
  | 'SHOPIFY_WEBHOOK_INTEGRITY'
  | 'REPORT_URI_SUBDOMAIN'
  | 'FACEBOOK_CONVERSIONS_API_TOKEN'

type EnvironmentVariableValues = Record<
  EnvironmentVariableKey,
  string | undefined
>

const defaultLocaleEnvironmentVariables: EnvironmentVariableValues = {
  NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID,
  NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN,
  SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD,
  SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY,
  REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN,
  FACEBOOK_CONVERSIONS_API_TOKEN: process.env.FACEBOOK_CONVERSIONS_API_TOKEN,
}

const localeEnvironmentVariables: Record<Locale, EnvironmentVariableValues> = {
  nl: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_NL,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_NL,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_NL,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_NL,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_NL,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_NL,
  },
  en: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_EN,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_EN,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_EN,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_EN,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_EN,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_EN,
  },
  fr: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_FR,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_FR,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_FR,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_FR,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_FR,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_FR,
  },
  de: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_DE,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_DE,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_DE,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_DE,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_DE,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_DE,
  },
}

/**
 * Gets environment variable for specific locale.
 */
export const getLocaleVariable = (
  locale: Locale,
  key: EnvironmentVariableKey
) => {
  const localeValue = localeEnvironmentVariables[locale]?.[key]

  if (typeof localeValue === 'undefined' || localeValue === '') {
    return defaultLocaleEnvironmentVariables[key]
  }

  return localeValue
}
