import { type DocumentDiscount } from '@lib/discount/types'
import { type ProductPriceOptions } from './types'

/**
 * Applies discount percent to price.
 */
export const applyDiscountPercent = (
  price: number | null,
  discountPercent: number | null
) => {
  if (price === null || discountPercent === null) {
    return price
  }

  const discountRate = discountPercent / 100
  return price * (1 - discountRate)
}

/**
 * Finds discount percent for a document in a list of document discounts.
 */
export const getDocumentPercentDiscount = (
  documentDiscounts: DocumentDiscount[],
  type: string,
  ids: string[]
) => {
  const normalizedIds = ids.filter(Boolean)
  const documentDiscount = documentDiscounts.find(
    (documentDiscount) =>
      documentDiscount.type === type &&
      normalizedIds.includes(documentDiscount.id)
  )

  return documentDiscount?.percentDiscount
}

/**
 * Gets product price and compare price without taxes.
 */
export const getProductPrices = (options: ProductPriceOptions) => {
  const {
    variantPrice,
    productPrice,
    variantComparePrice,
    productComparePrice,
    productPercentDiscount,
    collectionPercentDiscount,
    companyPercentDiscount,
  } = options

  let price = variantPrice ?? productPrice ?? null
  let comparePrice = variantComparePrice ?? productComparePrice ?? null

  // Apply customer-related discounts
  if (comparePrice === null) {
    const percentDiscounts = [
      productPercentDiscount ?? null,
      collectionPercentDiscount ?? null,
      companyPercentDiscount ?? null,
    ].filter((percentDiscount) => percentDiscount !== null)

    if (percentDiscounts.length > 0) {
      comparePrice = price
      price = applyDiscountPercent(price, percentDiscounts[0] ?? null)
    }
  }

  return [price ?? undefined, comparePrice ?? undefined] as const
}
