export type ScreenSizeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type FontSizeName =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'

export const screenSizeNames: ScreenSizeName[] = ['xs', 'sm', 'md', 'lg', 'xl']

export const PIXELS_PER_REM = 16

export const screens: Record<ScreenSizeName, string> = {
  xs: '480px',
  sm: '768px',
  md: '940px',
  lg: '1200px',
  xl: '1600px',
}

export const fontSizes: Record<FontSizeName, string> = {
  xxs: '0.75rem',
  xs: '0.875rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.25rem',
  xl: '1.25rem',
  '2xl': '1.625rem',
  '3xl': '2rem',
  '4xl': '2.375rem',
  '5xl': '2.875rem',
}

const colorSafelist = ['pageBG', 'pageText']

const spacingSafelist = [
  0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64,
].map((spacing) => `${spacing}`)

const range = (start: number, end: number) =>
  Array(end)
    .fill(undefined)
    .map((_x, i) => `${start + i}`)

const generateClasses = (
  property: string,
  values: string[],
  responsive = true,
  negative = false
) => {
  const classes: string[] = []

  for (const value of values) {
    const c = `${property}-${value}`
    classes.push(c)

    if (negative) {
      classes.push(`-${c}`)
    }

    if (responsive) {
      for (const screenSizeName of screenSizeNames) {
        classes.push(`${screenSizeName}:${c}`)

        if (negative) {
          classes.push(`-${screenSizeName}:${c}`)
        }
      }
    }
  }

  return classes
}

export const clampBuilder = (minFontSize: number, maxFontSize: number) => {
  const minWidthPx = Number(screens.xs.replace('px', ''))
  const maxWidthPx = Number(screens.md.replace('px', ''))

  const minWidth = minWidthPx / PIXELS_PER_REM
  const maxWidth = maxWidthPx / PIXELS_PER_REM

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minFontSize

  return `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`
}

export const safelist = [
  ...generateClasses('text', ['left', 'center', 'right', 'justify']),
  ...generateClasses('animate', ['none', 'spin', 'ping', 'pulse', 'bounce']),
  ...generateClasses('grid-cols', [...range(1, 12), 'none']),
  ...generateClasses('col-span', [...range(1, 12), 'full']),
  ...generateClasses('col-start', [...range(1, 13), 'auto']),
  ...generateClasses(
    'order',
    [...range(0, 37), 'first', 'last', 'none'],
    true,
    true
  ),
  ...generateClasses('self', ['auto', 'start', 'end', 'center', 'stretch']),
  ...generateClasses('justify-self', [
    'auto',
    'start',
    'end',
    'center',
    'stretch',
  ]),
  ...generateClasses('max-w', [
    '0',
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7x',
    'full',
    'min',
    'max',
    'prose',
    'screen-sm',
    'screen-md',
    'screen-lg',
    'screen-xl',
    'screen-2xl',
  ]),
  ...generateClasses('min-h', ['screen', 'xs', 'sm', 'md', 'lg', 'xl']),
  ...generateClasses('pt', [
    '[100%]',
    '[calc((3/4)*100%)]',
    '[calc((7/5)*100%)]',
    '[calc((6/4)*100%)]',
    '[calc((9/16)*100%)]',
    '[calc((7/16)*100%)]',
  ]),
  ...generateClasses('pt', spacingSafelist, true),
  ...generateClasses('pb', spacingSafelist, true),
  ...generateClasses('pr', spacingSafelist, true),
  ...generateClasses('pl', spacingSafelist, true),
  ...generateClasses('mt', spacingSafelist, true),
  ...generateClasses('mb', spacingSafelist, true),
  ...generateClasses('mr', spacingSafelist, true),
  ...generateClasses('ml', spacingSafelist, true),
  ...generateClasses('text', colorSafelist),
  ...generateClasses('bg', colorSafelist, true),
  ...generateClasses('before:bg', colorSafelist, true),
  ...generateClasses('after:bg', colorSafelist, true),
]

export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#FFFFFF',
  gray: {
    '50': '#F0F5FF',
    '100': '#F4F7FE',
    '200': '#E2E6F0',
    '700': '#394454',
    '800': '#2F3B4D',
  },
  blue: '#1D62E3',
  orange: '#FB6533',
  green: '#5BD693',
  red: '#FF514D',
  yellow: '#FFEBB8',
}

export const semanticColors = {
  pageBG: colors.white,
  pageText: colors.black,
  backdrop: colors.black,
  header: {
    bg: colors.white,
    text: colors.black,
  },
  box: {
    bg: colors.white,
    border: colors.black,
  },
  btn: {
    filled: {
      text: {
        DEFAULT: colors.white,
        hover: colors.white,
      },
      bg: {
        DEFAULT: colors.orange,
        hover: colors.orange,
      },
      border: {
        DEFAULT: colors.orange,
        hover: colors.orange ,
      },
    },
    outlined: {
      text: {
        DEFAULT: colors.black,
        hover: colors.white,
      },
      bg: {
        DEFAULT: colors.transparent,
        hover: colors.black,
      },
      border: {
        DEFAULT: colors.gray['200'],
        hover: colors.gray['200'],
      },
    },

  },
  divider: colors.gray['200'],
  input: {
    border: colors.gray['200'],
    text: colors.black,
    bg: colors.transparent,
    inverted: {
      border: colors.white,
      text: colors.white,
      bg: colors.transparent,
    },
  },
  checkbox: {
    unchecked: {
      bg: colors.white,
      border: colors.black,
      text: colors.white,
    },
    checked: {
      bg: colors.blue,
      border: colors.blue,
      text: colors.white,
    },
  },
  filterChip: {
    bg: colors.blue,
    text: colors.white,
  },
  cartCountBadgeBg: colors.blue,
  success: colors.green,
  warning: colors.yellow,
  error: colors.red,
  bgGray: colors.gray['100'],
}
