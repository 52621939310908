import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSummarySubtotalProps {
  price: number
  className?: string
}

const CartSummarySubtotal = ({
  price,
  className,
}: CartSummarySubtotalProps) => {
  const { currency, taxesIncluded, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const formattedPrice = useMemo(() => {
    if (typeof taxesIncluded === 'undefined') {
      return
    }

    return getPriceWithCurrency(price, currency, {
      taxRate,
      applyTax: !taxesIncluded,
      showCurrency: true,
      hasTrailingZeros: true,
    })
  }, [currency, price, taxesIncluded, taxRate])

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <span>{strings.cartSubtotal}</span>
      <span className="shrink-0">{formattedPrice}</span>
    </div>
  )
}

export default CartSummarySubtotal
