import { type Locale } from '@lib/language'

/**
 * Adds locale param to URL.
 */
const addLocaleParamToUrl = (url: string, locale: Locale) => {
  const searchParams = new URLSearchParams(`locale=${locale}`)
  const newUrl = new URL(url)
  searchParams.forEach((value, key) => newUrl.searchParams.set(key, value))

  return newUrl.toString()
}

/**
 * Formats checkout URL by replacing host and adding linker param.
 */
export const formatCheckoutUrl = async (
  checkoutUrl: string,
  locale: Locale,
  storeUrl?: string
) => {
  let formattedCheckoutUrl = `${checkoutUrl}`

  // Update checkout URL to use custom domain name
  if (storeUrl) {
    formattedCheckoutUrl = formattedCheckoutUrl.replace(
      /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
      storeUrl
    )
  }

  formattedCheckoutUrl = addLocaleParamToUrl(formattedCheckoutUrl, locale)
  // TODO: Add Google Analytics linker param to URL.

  return formattedCheckoutUrl
}
