import axios from 'axios'

import {
  type CartFragmentFragment,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import { type Locale } from './language'

type ShopFragment = ShopFragmentFragment & {
  taxesIncluded: boolean
}

export interface ShopInformation {
  locale: Locale
  shop?: ShopFragment
  cart?: CartFragmentFragment
}

export type ShopInformationResponse = ShopInformation

/**
 * Calls API page to get Shopify store information.
 */
export const getShopInformation = async (locale: Locale, cartId?: string) => {
  const response = await axios.get<ShopInformationResponse>(
    '/api/shopify/shop-information',
    {
      params: {
        cart_id: cartId,
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Locale': locale,
      },
    }
  )

  return response.data
}
