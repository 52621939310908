import { useContext, useMemo } from 'react'

import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import PriceBadge, {
  type PriceBadgeProps,
  type PriceBadgeStrings,
} from '@components/price-badge'

type ProductPriceProps = Omit<
  PriceBadgeProps,
  'currency' | 'taxesIncluded' | 'strings' | 'taxRate'
>

const ProductPrice = (props: ProductPriceProps) => {
  const { currency, taxesIncluded, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const priceBadgeStrings = useMemo<PriceBadgeStrings>(
    () => ({
      productDiscountText: strings.productDiscountText,
      taxExcludedText: strings.taxExcludedText,
      taxIncludedText: strings.taxIncludedText,
    }),
    [strings]
  )

  return (
    <PriceBadge
      {...props}
      currency={currency}
      taxRate={taxRate}
      taxesIncluded={taxesIncluded}
      strings={priceBadgeStrings}
    />
  )
}

export default ProductPrice
